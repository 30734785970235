<template>
  <div class="main">
    <div class="nav">
      <header-base :sear="'hide'"></header-base>
    </div>

    <div class="content">
      <div class="title_car">
        <div class="left_img">
          <img :src="message.productPic" alt="" class="detailimg">
          <el-carousel :interval="5000" type="card"
          indicator-position="none" height="58px">
            <el-carousel-item v-for="item in this.imglist" :key="item"
            >
              <img :src="item">
              <!-- <p>{{item}}</p> -->
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="right_auction_car">
          <el-form ref="message" :model="message" label-width="500px">

            <el-form-item :label="$t('m.auction_englishauction.bidding_is_ready_to_begin')" label-width="auto"
            class="countdown" v-if="auctionStatus == 0"
            >
              <span>{{$t('m.auction_englishauction.From_the_begin_there_only_one_left')}}:
                <count-down></count-down>
              </span>
            </el-form-item>
            <el-form-item :label="$t('m.auction_englishauction.Bidding_in_progress')" label-width="auto"
            class="countdown" v-if="auctionStatus == 1"
            >
              <span>{{$t('m.auction_englishauction.The_end_of_the_distance_is_only_left')}}:
                <count-down></count-down>
              </span>
            </el-form-item>
            <el-form-item :label="$t('m.auction_englishauction.Unsettled')" label-width="auto"
            class="countdown" v-if="auctionStatus == 2"
            >
              <span>{{$t('m.auction_englishauction.finished')}}</span>
            </el-form-item>
            <el-form-item :label="$t('m.auction_englishauction.traded')" label-width="auto"
            class="countdown" v-if="auctionStatus == 3"
            >
              <span>{{$t('m.auction_englishauction.completed')}}</span>
            </el-form-item>
            <el-form-item :label="$t('m.auction_englishauction.product_name')+':'" :label-width="$t('m.auction_englishauction.Delivery_width')">
              <span>{{message.productName}}</span>
            </el-form-item>
            <el-form-item :label="$t('m.auction_englishauction.present_price')+':'" :label-width="$t('m.auction_englishauction.Delivery_width')">
              <span>￥<NologindataVue :price='smsAuctionSku.realPrice' />/{{message.unit}}</span>
            </el-form-item>
             <!-- 已拍数量/库存 -->
            <el-form-item :label="$t('m.auction_englishauction.Quantity_sold')+':'" :label-width="$t('m.auction_englishauction.Delivery_width')">
              <span>{{ smsAuctionSku.stock}}{{ message.unit }}</span>
            </el-form-item>
            <!-- 保证金 -->
            <el-form-item :label="$t('m.auction_englishauction.Deposit_required')+':'" :label-width="$t('m.auction_englishauction.Delivery_width')"
            class="earnestmoney">
              <div class="ear_money_div">
                <span class="ear_money_span">{{message.percent}}</span>
              </div>
            </el-form-item>
            <el-form-item :label="pageType == 0?`${$t('m.auction_englishauction.bid')}:`:`${$t('m.auction_englishauction.Current_price')}/${$t('m.auction_englishauction.purchase_quantity')}:`" :label-width="$t('m.auction_englishauction.Delivery_width')"
              class="earnestmoney">
              <div class="price_buystock">
                ￥<el-input-number v-model="this.realPrice" v-if="pageType == 0"
                :model-value="this.realPrice"
                :step="smsAuctionSku.minqj"
                :min="smsAuctionSku.realPrice"
                @change="handleChangePrice" />
                <!-- 抢拍量 -->
                <!-- <span v-if="pageType == 1" style="margin-right:20px;"
                ><NologindataVue :price='this.realPrice' /></span>

                <el-input-number v-model="smsAuctionSku.minqp"
                :model-value="smsAuctionSku.minqp"
                :step="smsAuctionSku.minjp"
                :min="minqps"
                :max="smsAuctionSku.stock"
                @change="handleChangeStock" />{{message.unit}} -->
              </div>

              <div class="ear_money_div">
                <!-- <el-button :disabled="auctionStatus!==1"
                 @click="sub">抢拍</el-button> -->
                 <div class="btn_div">
                  <el-button :disabled="auctionStatus!==1"
                    class="gobutton gm" :class="$t('m.auction_englishauction.Likelihood_of_snatched')=='auction'?'':'gmleft'"
                    size="small"
                    @click="sub">
                      {{$t('m.auction_englishauction.Likelihood_of_snatched')}}
                  </el-button >
                  <el-button @click="likeVarietiy" size="small" class="gobutton gz" :class="$t('m.auction_englishauction.Likelihood_of_snatched')=='auction'?'':'gmleft'">
                      {{$t('m.reservation_varietiy_details.attention')}}
                  </el-button >
                </div>
                <a @click="isact=1,changNaviBar({ name: '竞拍说明', href: '#auctions' },1)">{{$t('m.auction_englishauction.Bidding_rules')}}</a>
              </div>
            </el-form-item>
            <div class="app_install" style="width:120px;">
              <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/PhoneApp.png" style="width:120px;height:120px;">
              <p style="text-align: center;">{{$t('m.auction_englishauction.Scan_code_to_download_APP')}}</p>
            </div>
          </el-form>

          <el-breadcrumb separator="|">
            <el-breadcrumb-item v-if="pageType == 0">
              {{$t('m.auction_englishauction.Minimum_markup_unit')}}：<span>￥{{smsAuctionSku.minqj}}/{{$t('m.auction_englishauction.sec')}}</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item v-if="pageType == 1">
              {{$t('m.auction_englishauction.price_cut')}}：<span>￥{{message.downPrice}}/{{message.cycle/60000}}{{$t('m.auction_englishauction.minute')}}</span>
            </el-breadcrumb-item>
            <!-- 最小起拍量 -->
            <!-- <el-breadcrumb-item>
              {{$t('m.auction_englishauction.Minimum_purchase_quantity')}}：<span>{{ minqps }}{{ message.unit }}</span>
            </el-breadcrumb-item> -->
            <!-- 最小加拍量 -->
            <!-- <el-breadcrumb-item>
              {{$t('m.auction_englishauction.Minimum_increase')}}：<span>{{ smsAuctionSku.minjp }}{{ message.unit }}</span>
            </el-breadcrumb-item> -->
            <el-breadcrumb-item v-if="pageType == 1">
              {{$t('m.auction_englishauction.deferred_cycle')}}：<span>{{(message.cycle/60000)+$t('m.auction_englishauction.minute')}}</span>
              <el-tooltip placement="right" effect="light">
                <template #content>
                  <!-- 竞拍最后{{(message.cycle/60000)}}分钟内若有用户<br/>出价，进入延时竞价周期 -->
                  {{$t('m.auction_englishauction.If_user_bids_the_bidding_extended', {time: (message.cycle/60000)})}}
                </template>
                <i class="icon_i">?</i>
              </el-tooltip>
            </el-breadcrumb-item>
          </el-breadcrumb>
          <p class="explanation">{{$t('m.auction_englishauction.If_the_auction_the__days')}}</p>
        </div>
      </div>
      <div class="particulars">
        <div class="parti_title">
          <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/title_img/jingpaiFow.png">
        </div>
        <div class="parti_body">
          <div class="supplier_message">
            <h3>{{$t('m.reservation_varietiy_details.Supplier_file')}}</h3>
            <p class="supname">{{companyInfo.companyName}}</p>
            <!-- <span class="supgood">好评率：{{(99.5561).toFixed(2)}}%</span> -->
            <div class="supxiangxi">
              <div class="suplogo">
                <img :src="companyInfo.logo" alt="">
              </div>
              <div class="xxdiv">
                <el-icon><location /></el-icon>
                <span>{{companyInfo.address}}</span></div>
              <div class="xxdiv">
                <el-icon><phone-filled /></el-icon>
                {{companyInfo.phoneNumber}}</div>
              <div class="xxdiv">
                <el-icon><avatar /></el-icon>
                <el-popconfirm
                  confirm-button-text="是的"
                  cancel-button-text="再考虑一下"
                  icon="InfoFilled"
                  icon-color="#626AEF"
                  :title="message.focusOnMerchant==1?'你要取关该供应商吗?':'你要关注该供应商吗?'"
                  @confirm="confirmEvent"
                  @cancel="cancelEvent"
                >
                  <template #reference>
                    <el-button size="mini">{{message.focusOnMerchant==1?$t('m.reservation_varietiy_details.followed'):$t('m.reservation_varietiy_details.attention')}}</el-button>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </div>
          <div class="supplier_detail">
            <ul class="title_ul">
              <li v-for="(i,index) in detailList" :key="index"
              :class="isact===index?'isactive':''">
                <a @click="isact=index,changNaviBar(i,index)">{{i.name}}</a>
              </li>
            </ul>
            <div class="details_mod">
              <h4 id="bids">{{$t('m.auction_englishauction.Bid_record')}}</h4>
              <div class="table_div">
                <table id="table_show">
                  <tr class="table_title">
                    <th v-for="i in modTable.title" :key="i">
                      {{i}}
                    </th>
                  </tr>
                  <tr class="table_cont"
                  v-for="(i,index) in modTable.cont" :key="index">
                    <td>￥{{i.price}}
                      <span class="maxprice"
                      v-if="index==0">{{$t('m.auction_englishauction.top_price')}}</span>
                    </td>
                    <td>{{i.username}}</td>
                    <td class="table_cont_time">{{i.createTime}}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="details_mod">
              <h4 id="auctions">{{$t('m.auction_englishauction.Bidding_rules')}}</h4>
              <p class="auctiontext">{{auctionstext}}</p>
            </div>
            <div class="details_mod">
              <h4 id="detailimg">{{$t('m.reservation_varietiy_details.Coffee_details')}}</h4>
              <img :src="auctionImgurl">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 支付定金弹窗 -->
    <el-dialog
      :title="`支付定金`"
      v-model="confirmstuts"
      :close-on-click-modal="false"
      width="30%"
    >
      <div v-if="pageType == 0">
        支付金额:<span class="payMoney">￥{{ money[2] }}</span>
      </div>
      <div v-else>
        支付定金:<span class="payMoney">￥{{ this.dinjing }}</span>
      </div>
      <div style="width: 200px; margin: 20px auto">
        <el-input
          placeholder="请输入支付密码"
          :show-password="true"
          maxlength="6"
          v-model="paypassword"
          type="password"
        ></el-input>
      </div>
      <span class="dialog-footer">
        <el-button @click="confirmstuts = false" size="small">取 消</el-button>
        <el-button type="primary" size="small"
        :loading="loadingBtn"
        @click="paymoneys">确 定</el-button>
      </span>
    </el-dialog>

    <div class="divfooter">
      <Footer/>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue-demi'
import Footer from '../../index/Footer.vue'
import HeaderBase from '../../../components/HeaderBase.vue'
import CountDown from '../../../components/CountDown.vue'
import NologindataVue from '../../../components/Nologindata.vue'
import http from '../../../api/http'
import { ElMessage } from 'element-plus'
import emitter from '../../../tools/bus'
// import { getStore } from '../../../tools/storage'
import moment from 'moment'
import { getStore } from '../../../tools/storage'

const headernav = [
  { text: '首页', path: '/' },
  { text: '登录', path: '/loginandregister' },
  { text: '移动端', path: '' }
]
const auctionDetail = (data) => {
  return http.get('/mall-buyer/auction/getAuctionInfo', data)
}
// 竞拍提交
const getSubtract = (data) => {
  return http.post('mall-buyer/auction/getSubtract', data)
}
// 首次竞拍支付
const rush = (data) => {
  return http.post('mall-buyer/auction/rush', data)
}
// 关注商品接口
const followTheProduct = (data) => {
  return http.post('/mall-portal/mall/pmsfollowtheproduct/followTheProduct', data)
}
// 关注店铺接口
const followTheStore = (data) => {
  return http.post('/mall-portal/mall/pmsfollowthestore/followTheStore', data)
}
// 取消关注店铺
const unpmsfollowthestore = (data) => {
  return http.post('/mall-portal/mall/pmsfollowthestore/deleteByMerchantId', data)
}
export default {
  components: { Footer, CountDown, HeaderBase, NologindataVue },
  name: 'EnglishAuction',
  data () {
    return {
      imglist: [],
      supplier: {
        name: '北京雀巢奈斯派索咖啡有限公司', // 公司名
        favorable: 99.94656, // 好评率
        area: '中国（上海）自由贸易试验区碧波路690号1幢402-1室', // 公司地址
        phone: '17788889999' // 电话
      },
      detailList: [
        { name: this.$t('m.auction_englishauction.Bid_record'), href: '#bids' },
        { name: this.$t('m.auction_englishauction.Bidding_specification'), href: '#auctions' },
        { name: this.$t('m.reservation_varietiy_details.Coffee_details'), href: '#detailimg' }
      ],
      modTable: reactive({ // 出价记录list
        title: [this.$t('m.auction_englishauction.price'), this.$t('m.auction_englishauction.bidder'), this.$t('m.auction_englishauction.Time')],
        cont: [
        ]
      }),
      auctionstext: '',
      auctionImgurl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/title_img/message01.jpg',
      isact: ref(0),

      timer: reactive({ // 竞拍时间
        eventEndTime: null,
        eventStartTime: null
      }),
      //   竞拍需要
      smsAuctionSku: {
        minqp: 0
      }, // 竞拍sku
      companyInfo: reactive({}), // 供应商
      auctionStatus: '', // 竞拍状态 0：待活动开始，1：活动中，2：未成交，3：已成交
      minqps: 0, // 起拍价
      oldminqp: '',
      message: reactive({}), // 详情数据
      realPrice: 0,
      qpnumber: '',
      confirmstuts: false,
      confirmnumbers: true,
      money: '',
      paypassword: '', // 支付密码
      pageType: '',
      valuess: 0,
      totalPrice: 0,
      dinjing: 0,
      pageStatus: '',
      scrollTop: 0,
      loadingBtn: false, // 支付按钮loading
      dataTime: '' // 请求的时间
    }
  },
  methods: {
    // 获取详情
    async initdata (id) {
      const loading = this.$loading({
        lock: true,
        text: '加载中..'
      })
      this.paypassword = ''
      // this.pageType = this.$route.query.type
      const queryid = id || this.$route.query.id
      this.dataTime = Date.parse(new Date())
      // console.log(this.pageType)
      // const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
      const userId = (getStore('usermessage') && getStore('usermessage').substring(0, 1) === '{') ? JSON.parse(getStore('usermessage')) : null

      const getData = {
        productId: queryid,
        userId: userId ? userId.id : null
      }
      const res = await auctionDetail(getData)
      if (res.data.code === 200) {
        this.message = res.data.data
        this.pageType = this.message.type
        this.auctionstext = this.pageType === 0 ? this.$t('m.auction_englishauction.English_bidding_rules')
          : this.$t('m.auction_englishauction.Dutch_bidding_rules')

        this.auctionStatus = res.data.data.status // 当前的竞拍商品状态
        // console.log(this.auctionStatus)

        this.companyInfo = this.message.umsCompanyInfoEntity
        this.smsAuctionSku = this.message.smsAuctionSku
        this.modTable.cont = this.message.smsAuctionHistoryList
        this.timer.eventStartTime = this.message.startTime
        this.timer.eventEndTime = this.message.endTime

        // console.log(this.timer)
        emitter.emit('englishAuctionTime', { data: this.timer, status: this.auctionStatus })
        // 初始化可能用的数据
        this.minqps = res.data.data.smsAuctionSku.minqp
        this.oldminqp = res.data.data.smsAuctionSku.minqp
        this.message = res.data.data
        this.smsAuctionSku = res.data.data.smsAuctionSku
        this.realPrice = Number(res.data.data.smsAuctionSku.realPrice.toFixed(2))
        this.qpnumber = res.data.data.smsAuctionSku.minqp
        this.smsAuctionSku.minqp = Number(this.smsAuctionSku.minqp)
        this.imglist = []
        // this.imglist.push(res.data.data.productPic)
        for (let i = 0; i < 3; i++) {
          this.imglist.push(res.data.data.productPic)
        }
        this.valuess = res.data.data.smsAuctionSku.minqp
        // this.imgList = imglist.split(',') // 商品轮播图
        this.indexpic = res.data.data.productPic

        this.totalPrice = this.valuess * this.smsAuctionSku.realPrice
        console.log(this.valuess)
        console.log(this.smsAuctionSku.realPrice)
        console.log(this.totalPrice)
        this.dinjing = (this.message.percent * this.totalPrice).toFixed(2)
        console.log(this.message.percent)
        console.log(this.dinjing)

        console.log(this.message)
        loading.close()
        return ''
      }
      ElMessage.error({
        message: '出错了,请刷新重试'
      })
      loading.close()
    },
    // 抢拍
    async sub () {
      // const userAccount = getStore('usermessage').userAccount
      const endtime = moment(this.message.endTime).format('YYYY-MM-DD HH:mm') // 截止日期
      // let s = moment(endtime).valueOf()//截止日期时间戳
      const newtime = moment(new Date()).format('YYYY-MM-DD HH:mm')
      // const suminap = Number(this.smsAuctionSku.minjp) + Number(this.minqps) // 最小加拍价最小起拍量
      // console.log('this.message:', this.message)
      // console.log('suminap', suminap)
      // console.log('最小加拍' + this.smsAuctionSku.minqp)
      // console.log('最小起拍量' + this.minqps)

      // console.log(newtime)
      // console.log(moment(endtime).isBefore(newtime))
      if (moment(endtime).isBefore(newtime)) {
        ElMessage.error('超出截止日期')
        return
      }
      if (this.pageType === 0) { // 英式
        if (this.realPrice <= this.message.smsAuctionSku.realPrice) {
          ElMessage.error('拍入单价低于当前单价')
          return
        }
        // if (this.smsAuctionSku.minqp < suminap) {
        //   ElMessage.error('拍入量错误')
        //   return
        // }
        if (this.message.minqp < this.minqps) {
          ElMessage.error('拍入量小于最低起拍量')
          return
        }

        if (!this.confirmnumbers) {
          return
        }
        const data = {
          aucId: this.message.id,
          skuId: this.smsAuctionSku.id,
          deposit: this.realPrice,
          price: this.realPrice,
          type: 0,
          num: this.message.smsAuctionSku.minqp
        }
        try {
          const res = await getSubtract(data, {})
          console.log(res)
          if (res.data.code !== 200) {
            ElMessage.warning({
              message: res.data.msg
            })
          }
          this.money = res.data.data
          // console.log(typeof this.money[2])
          if (this.money[2] === 0) {
            // 不需要付定金
            this.paymoneysno()
            console.log('不需要付定金')
          } else {
            console.log('需要付定金')
            this.confirmstuts = true
            // this.paymoneys()
          }
        } catch (e) {
        // TODO handle the exception
        }
      } else { // 荷兰式
        if ((this.dataTime + this.message.cycle) <= Date.parse(new Date())) {
          ElMessage.warning({ message: '数据有更新' })
          this.initdata(this.message.id)
          return
        }
        try {
          console.log('需要付定金')
          this.valuess = this.smsAuctionSku.minqp // 拍入量
          this.confirmstuts = true
          // this.paymoneys()
        } catch (e) {
        // TODO handle the exception
        }
      }
    },
    handleChangePrice (e) { // 价格限制
      console.log(e)
      if (!/^[1-9]\d*$/.test(e)) {
        ElMessage.error('价格只能是整数')
        this.realPrice = parseInt(e)
        console.log(this.realPrice)
        return ''
      }
      if (e > 99999999) {
        ElMessage.error('最高99999999')
        this.realPrice = parseInt(99999999)
        console.log(this.realPrice)
        return ''
      }
      // const values = Number(e)
      // const minqjs = Number(this.message.smsAuctionSku.minqj)
      // console.log(minqjs)
      // const realPrice = this.message.smsAuctionSku.realPrice // 当前单价
      // console.log(realPrice)
      // const realPrices = realPrice + minqjs
      // console.log(realPrices)

      // if (values < realPrices) {
      //   ElMessage.error('拍入单价低于当前单价')
      //   this.realPrice = Number(realPrice.toFixed(2))
      //   return
      // }
      // this.realPrice = Number(values.toFixed(2))
    },
    handleChangeStock (e) { // 拍入量限制
      if (!/^[1-9]\d*$/.test(e)) {
        ElMessage.error('拍入量只能是整数')
        this.smsAuctionSku.minqp = parseInt(e)
        console.log(this.smsAuctionSku.minqp)
        return ''
      }
      if (this.pageType === 1) { // 荷兰式
        console.log(e)
        this.totalPrice = e * this.smsAuctionSku.realPrice
        this.dinjing = (this.message.percent * this.totalPrice).toFixed(2)
      }
      // console.log(e)
    },
    // 定金为0
    async paymoneysno () {
      const data = {
        aucId: this.message.id,
        skuId: this.smsAuctionSku.id,
        deposit: this.money[2],
        price: this.realPrice,
        type: 0,
        num: this.message.smsAuctionSku.minqp
      }
      try {
        const res = await rush(data, {})
        if (res.data.code === 200) {
          this.$message({
            message: '恭喜你，拍入成功',
            type: 'success'
          })
          this.loadingBtn = false
          this.initdata(this.message.id)
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          })
        }
      } catch (e) {
        this.loadingBtn = false
      }
    },
    async paymoneys () { // 付定金
      this.loadingBtn = true
      const paypassword = this.paypassword
      // console.log(this.pageType)
      if (!paypassword) {
        ElMessage.error('请输入密码')
        return
      }
      //   this.disabled = true;
      const usermessage = getStore('usermessage')
      if (!usermessage) {
        ElMessage.warning({
          message: '暂未登录，请登录后抢拍'
        })
        this.$router.push('/loginandregister')
        return
      }
      const capitalAccount = JSON.parse(usermessage).capitalAccount
      //   let userAccount = getStore("phuse").userAccount;
      const md5password = this.$md5(`${capitalAccount}${paypassword}`)

      // const token = ck.getCookie('token') || ''
      // const urls = this.$url
      // console.log(urls)
      let data = ''
      if (this.pageType === 0) {
        data = {
          aucId: this.message.id,
          skuId: this.smsAuctionSku.id,
          deposit: this.money[2],
          price: this.realPrice,
          type: 0,
          password: md5password,
          num: this.message.smsAuctionSku.minqp
        }
      } else {
        data = {
          memberId: '',
          aucId: this.message.id,
          skuId: this.smsAuctionSku.id,
          price: this.realPrice,
          num: this.valuess,
          deposit: this.totalPrice,
          type: 1,
          status: this.message.status,
          orderSn: '',
          string: '',
          createTime: this.message.createTime,
          updateTime: '',
          remark: '',
          password: md5password
        }
      }

      try {
        console.log(data)
        const res = await rush(data, {})
        console.log('数据显示', res)
        if (res.data.code === 200) {
          this.confirmstuts = false
          ElMessage({
            message: '拍入成功',
            type: 'success'
          })
          console.log(res)
          //   this.disabled = false;
          this.loadingBtn = false
          this.paypassword = ''
          this.initdata(this.message.id)
        } else {
          console.log('erro错误')
          ElMessage({
            message: res.data.msg,
            type: 'error'
          })
          this.confirmstuts = false
          this.loadingBtn = false
          ElMessage.error(res.data.msg)
          // console.log(res.data.msg)
          setTimeout(() => {
            this.initdata(this.message.id)
          }, 1000)
        }
      } catch (e) {
        this.paypassword = ''
        // this.disabled = false;
        // TODO handle the exception
      }
    },
    async likeVarietiy () { // 关注商品
      const data = {
        previewStatus: 2,
        productId: this.$route.query.id,
        productSn: null
      }
      const res = await followTheProduct(data)
      console.log(res)
      if (res.data.code === 200) {
        ElMessage.success({
          message: res.data.msg
        })
      } else {
        ElMessage.warning({
          message: res.data.msg
        })
      }
    },
    changNaviBar (i, index) {
      var PageId = document.querySelector(i.href)
      // 打印出对应页面与窗口的距离
      // console.log(PageId.offsetTop)

      // 使用平滑属性，滑动到上方获取的距离
      // 下方我只设置了top，当然 你也可以加上 left 让他横向滑动
      // widow 根据浏览器滚动条，如果你是要在某个盒子里面产生滑动，记得修改
      window.scrollTo({
        top: PageId.offsetTop - 10,
        behavior: 'smooth'
      })
    },
    async confirmEvent () { // 关注供应商
      // console.log('confirm!')
      const data = {
        merchantId: this.companyInfo.memberId
      }
      if (this.message.focusOnMerchant === 1) {
        const res = await unpmsfollowthestore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: '取关成功'
          })
          this.message.focusOnMerchant = 0
        } else {
          ElMessage.error({
            message: res.data.msg
          })
        }
      } else {
        const res = await followTheStore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: '关注成功!'
          })
          this.message.focusOnMerchant = 1
        } else {
          ElMessage.error({
            message: res.data.msg
          })
        }
      }
    },
    cancelEvent () {
      // console.log('cancel!')
    }
  },
  mounted () {
    window.document.documentElement.scrollTop = 0 // 渲染 详情组件时让 回到顶部
    this.initdata()
    this.initdata()
    this.paypassword = ''
  },
  setup () {
    return {
      headernav
    }
  }
}
</script>

<style  lang="scss">
.main{
  // display: flex;
  // flex-flow: column;
  width: 100%;
  // background: $divbgColor;
  background: #F2EFED;
  .nav{
    // height: 30px;
    width: 100%;
    background: #ffffff;
    ul{
      display: block;
      line-height: 30px;
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      li{
        float: left;
        display: flex;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    #search{
      width: 100%;
      height: 120px;
      background: #ffffff;
      .logosearch{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        z-index: 99;
        justify-content: left;
        align-items: center;
        #logo{
          img{
            cursor: pointer;
            margin-right: 80px;
            z-index: 9999;
          }
        }
      }
    }
  }
  .content{
    width: 1200px;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    flex-flow: column;
    .title_car{
      display: flex;
      background: #FFFFFF;
      margin-bottom: 10px;
      .left_img{
        padding: 20px;
        border-right: 1px solid #f2f2f2;
        width: 360px;
        .detailimg{
          height: 360px;
          width: 360px;
        }
        .el-carousel{
          .el-carousel__container{
            .el-carousel__item{
              // width: 60px;
              img{
                display: block;
                margin: 0 auto;
                width: 58px;
                height: 58px;
              }
            }
          }
        }
      }
      .right_auction_car{
        flex: 1;
        padding:20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        .el-form{
          position: relative;
          .app_install{
            position: absolute;
            top: 70px;
            right: 60px;
          }
          .el-form-item{
            margin-bottom: 10px;
            .el-form-item__label{
              text-align-last: justify;
              text-align: left;
              padding-left:20px;
            }
            .el-form-item__content{
              padding-left: 10px;
            }
          }
          .countdown{
            height: 50px;
            line-height: 50px;
            align-items: center;
            background: #C6824E;
            color: #FFFFFF;
            .el-form-item__label{
              color: #FFFFFF;
            }
            .el-form-item__content{
              font-size: 20px;
              font-weight: bold;
            }
          }
          .earnestmoney{
            .price_buystock{
              display: flex;
              margin-bottom: 20px;
              .el-input-number{
                margin-right: 20px;
              }
            }
            .ear_money_div{
              display: flex;
              align-items: flex-end;
              .btn_div{
                // float: right;
                width: 170px;
                position: relative;
                height: 52px;
                background: url('../../../static/imges/42559e2c11e8b82ba91e451581be42b.png') no-repeat;
                .gobutton{
                  position: absolute;
                  padding: 0;
                  top: 11px;
                  font-size: 14px;
                  height: 30px;
                  background: transparent;
                  border: none;
                  border-radius: 4px;
                }
                .gmleft{
                  padding: 9px 15px;
                }
                .gz{
                  left: 11px;
                  color: #673000;
                }
                .gm{
                  left: 96px;
                  // height: 30px !important;
                  color: #F2EFED;
                }
              }
              a{
                color: #C6824E;
                text-decoration:underline;
              }
            }
          }
        }
        .el-breadcrumb{
          margin:30px 0 30px;
          padding: 0 20px;
          .el-breadcrumb__item{
            margin: 5px 0;
            .el-breadcrumb__inner{
              color: #999999;
              span{
                color: #333333;
              }
              .icon_i{
                margin-left: 10px;
                width: 15px;
                text-align: center;
                display: inline-block;
                color: #ffffff;
                border-radius: 50%;
                font-weight: bolder;
                background: #999999;
                cursor: pointer;
              }
            }
            .el-breadcrumb__separator{
              margin: 0 20px;
            }
          }
        }
        .explanation{
          color: #999999;
          padding: 0 20px;
        }
      }
    }
    .particulars{
      .parti_body{
        margin-top: 10px;
        display: flex;
        .supplier_message{
          width: 240px;
          height: 530px;
          margin-bottom: 50px;
          background: #FFFFFF;
          border: 1px solid #C6824E;
          padding: 0 20px;
          display: flex;
          flex-flow: column;
          h3{
            text-align: center;
            padding: 20px;
            font-size: 20px;
            font-weight: bold;
            background: linear-gradient(0deg, #280E01 0%, #673000 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            border-bottom: 2px solid #2B1001;
          }
          .supname{
            margin-top: 18px;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
          }
          .supgood{
            margin-top: 18px;
            font-size: 12px;
            color: #C6824E;
          }
          .supxiangxi{
            flex: 1;
            margin: 20px 0;
            .xxdiv{
              display: flex;
              color: #666666;
              margin-top: 18px;
              font-size: 14px;
              line-height: 21px;
              .el-icon{
                margin-right: 5px;
                margin-top: 3px;
              }
              button{
                width: 80px;
                height: 26px;
                border: 1px solid #EAEAEA;
                border-radius: 4px;
                background: transparent;
                color: #666666;
              }
            }
            .suplogo{
              width: 100%;
              img{
                width: 200px;
                // height: 200px;
                border-radius: 50%;
              }
            }
          }
        }
        .supplier_detail{
          flex: 1;
          background: #FFFFFF;
          scroll-behavior: smooth;
          .title_ul{
            height: 50px;
            border-bottom: 1px solid #F2EFED;
            li{
              float: left;
              text-align: center;
              min-width: 120px;
              height: 50px;
              a{
                line-height: 50px;
                color: #333333;
              }
            }
            .isactive{
              background: #C6824E;
              a{
                color: #FFFFFF;
              }
            }
          }
          .details_mod{
            padding: 20px;
            h4{
              margin: 16px 0;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #C6824E;
            }
            .table_div{
              padding: 20px;
              box-sizing: border-box;
              // height: 310px;
              background: #FFFFFF;
              border: 1px solid #EAEAEA;
              #table_show{
                display: flex;
                flex-flow: column;
                tr{
                  padding:0 20px;
                  display: flex;
                  line-height: 30px;
                }
                .table_title{
                  height: 30px;
                  background: #F7F7F7;
                  th{
                    flex: 1;
                    text-align: left;
                    color: #999999;
                  }
                }
                .table_cont{
                  height: 29px;
                  line-height: 29px;
                  border-bottom: 1px solid #F7F7F7;
                  td{
                    flex: 1;
                    .maxprice{
                      display: inline-block;
                      text-align: center;
                      line-height: 20px;
                      // min-width: 44px;
                      padding: 0 5px;
                      height: 20px;
                      background: #C6824E;
                      font-size: 12px;
                      color: #FFFFFF;
                    }
                  }
                  .table_cont_time{
                    color: #999999;
                  }
                }
              }
            }
            .auctiontext{
              text-indent: 2em;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
  .divfooter{
    background: #280E01;
  }
}
</style>
